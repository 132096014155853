$(document).ready(function() {
	$(".category-toggle").fancybox({
		baseClass: "category-modal"
	});

	headerUtil();
	personAdjustment();
	initSliderPrice();
	dropdownFilterToggle();
	initCalendar();
	detailTourUtil();
});

function detailTourUtil() {
	$(".person-picker .person-toggle").click(function() {
		$(this)
			.next()
			.slideToggle("fast");
	});
	$(".detail-tour-section .tabs").tabslet({
		container: ".detail-tour-section .tabs-container",
		animation: true,
		deeplinking: false
	});
}

function headerUtil() {
	$("header").sticky({
		topSpacing: 0,
		zIndex: 999
	});
	$("header").headroom({
		offset: 0,
		// scroll tolerance in px before state changes
		tolerance: 0,
		// or scroll tolerance per direction
		tolerance: {
			down: 0,
			up: 0
		}
	});
}

function dropdownFilterToggle() {
	$(".filter-item .dropdown .dropdown-toggle").click(function() {
		$(this)
			.next()
			.slideToggle("fast");

		$(this)
			.parents(".filter-item")
			.siblings()
			.find(".dropdown-menu")
			.slideUp();
	});
	$(document).on("click", function(e) {
		if ($(e.target).closest(".dropdown").length === 0) {
			$(".dropdown-menu").slideUp();
		}
	});
}

function initCalendar() {
	$(".dateRangePicker").flatpickr({
		minDate: "today",
		mode: "range",
		dateFormat: "M d",
		locale: {
			rangeSeparator: " - "
		},
		onClose: function(dateObj, dateStr) {
			$("#startedDate").val(flatpickr.formatDate(dateObj[0], "d-m-Y"));
			if (dateObj[1].toString() !== dateObj[0].toString()) {
				$("#endedDate").val(flatpickr.formatDate(dateObj[1], "d-m-Y"));
			}

			console.log("start: " + $("#startedDate").val());
			console.log("end: " + $("#endedDate").val());
		}
	});

	var calendar = $(".dateRangePicker2").flatpickr({
		minDate: "today",
		dateFormat: "D, M d, Y",
		disableMobile: true,
		onChange: function(dateObj, dateStr) {
			$("#dateResult").val(flatpickr.formatDate(dateObj[0], "d-m-Y"));
			console.log($("#dateResult").val());
		}
	});
}

function initSliderPrice() {
	$("#priceSlider").slider({
		range: true,
		min: Number($("#priceSlider").attr("data-price-min")),
		max: Number($("#priceSlider").attr("data-price-max")),
		values: [
			Number($("#priceSlider").attr("data-price-min")),
			Number($("#priceSlider").attr("data-price-max"))
		],
		create: function(event, ui) {
			$("#priceMin").val(event.target.dataset.priceMin);
			$("#priceMax").val(event.target.dataset.priceMax);
		},
		slide: function(event, ui) {
			$("#priceMin").val(ui.values[0]);
			$("#priceMax").val(ui.values[1]);
			$("#priceCurrent").text("$" + ui.values[0] + " - $" + ui.values[1]);
		}
	});
	$("#priceMin").on("change", function() {
		var min = Number($(this).val());
		$("#priceSlider").slider("values", 0, min);
	});
	$("#priceMax").on("change", function() {
		var max = Number($(this).val());
		$("#priceSlider").slider("values", 1, max);
	});
}

function changeSelectionColor() {
	$(".booking-wrapper .selection").each(function() {
		let _quantityNumber = Number(
			$(this)
				.find(".quantity")
				.text()
		);
		if (_quantityNumber > 0) {
			$(this).css({
				color: "#209d9c"
			});
		} else {
			$(this).css({
				color: "#999999"
			});
		}
	});
}

function personAdjustment() {
	$(".booking-wrapper .selection .desc").click(function() {
		let _quantity = $(this).next();
		let _quantityNumber = Number(
			$(this)
				.next()
				.text()
		);
		if (_quantity.is("#adultPerson")) {
			if (_quantityNumber <= 1) {
				$(this)
					.next()
					.text("1");
			} else {
				_quantityNumber--;
				$(this)
					.next()
					.text(_quantityNumber);
			}
		} else {
			if (_quantityNumber === 0) {
				$(this)
					.next()
					.text("0");
			} else {
				_quantityNumber--;
				$(this)
					.next()
					.text(_quantityNumber);
			}
		}
		changeSelectionColor();
	});

	$(".booking-wrapper .selection .inc").click(function() {
		let _quantityNumber = Number(
			$(this)
				.prev()
				.text()
		);
		_quantityNumber++;
		$(this)
			.prev()
			.text(_quantityNumber);
		changeSelectionColor();
	});
	personSelectionApply();
}

function personSelectionApply() {
	$(".person-selection .btn-apply").on("click", function() {
		let _adultQuantity = Number($("#adultPerson").text());
		let _adultText = $("#adultPerson").data("name");
		let _childrenQuantity = Number($("#childPerson").text());
		let _childrenText = $("#childPerson").data("name");
		let _infantQuantity = Number($("#infantPerson").text());
		let _infantText = $("#infantPerson").data("name");
		let _displayText = [];

		if (_adultQuantity >= 1) {
			_displayText.push(_adultQuantity + " " + _adultText);
		}

		if (_childrenQuantity > 0) {
			_displayText.push(_childrenQuantity + " " + _childrenText);
		}

		if (_infantQuantity > 0) {
			_displayText.push(_infantQuantity + " " + _infantText);
		}
		$(".display-text").text(_displayText.join(", "));
		$(".person-selection").slideUp();
		$(".total-wrapper").show();
	});
}

//Header Mappung - Begin
let header_top = new MappingListener({
	selector: ".header-right-top",
	mobileWrapper: ".header-wrapper",
	mobileMethod: "insertBefore",
	desktopWrapper: ".header-right-bottom",
	desktopMethod: "insertBefore",
	breakpoint: 1025
}).watch();

let cart = new MappingListener({
	selector: ".cart-wrapper",
	mobileWrapper: ".phone-wrapper",
	mobileMethod: "insertAfter",
	desktopWrapper: ".account-wrapper",
	desktopMethod: "insertAfter",
	breakpoint: 1025
}).watch();
//Header Mappung - End

//Swiper init - Begin
let homeBanner = new Swiper(".home-banner .swiper-container", {
	effect: "fade",
	fadeEffect: {
		crossFade: true
	},
	speed: 2000,
	autoplay: {
		delay: 4500,
		disableOnInteraction: false
	},
	pagination: {
		el: ".home-banner .swiper-pagination",
		type: "bullets",
		clickable: true
	}
});

let hot_destinations = new Swiper(".hot-destinations .swiper-container", {
	slidesPerColumn: 2,
	slidesPerView: 4,
	spaceBetween: 30,
	slidesPerColumnFill: "row",
	allowTouchMove: false,
	breakpoints: {
		1200: {
			allowTouchMove: true,
			slidesPerView: 3.5
		},
		768: {
			allowTouchMove: true,
			slidesPerView: 2.5
		},
		576: {
			allowTouchMove: true,
			slidesPerView: 1.5
		}
	}
});

let popular_tours = new Swiper(".popular-tours .swiper-container", {
	slidesPerColumn: 2,
	slidesPerView: 4,
	spaceBetween: 30,
	slidesPerColumnFill: "row",
	allowTouchMove: false,
	breakpoints: {
		1200: {
			allowTouchMove: true,
			slidesPerView: 3.5
		},
		768: {
			allowTouchMove: true,
			slidesPerView: 2.5
		},
		576: {
			allowTouchMove: true,
			slidesPerView: 1.5
		}
	}
});

let tourImages = new Swiper(".tour-images .swiper-container", {
	speed: 2000,
	spaceBetween: 5,
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false
	},
	navigation: {
		prevEl: ".tour-images .swiper-prev",
		nextEl: ".tour-images .swiper-next"
	}
});

let tourOthers = new Swiper(".other-tour-list .swiper-container", {
	speed: 2000,
	slidesPerView: 3,
	spaceBetween: 30,
	navigation: {
		prevEl: ".other-tour-list .swiper-prev",
		nextEl: ".other-tour-list .swiper-next"
	},
	breakpoints: {
		576: {
			slidesPerView: 2.5
		},
		476: {
			slidesPerView: 1.5
		}
	}
});
//Swiper end - Begin
